import handleArrayWIthProperties_ypljaokOlv from "/vercel/path0/app/plugins/handleArrayWIthProperties.ts";
import revive_payload_client_ZG5wPPp8u4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_@upstash+redis@1.34.4_@vercel+kv@3.0.0__cvb6blnd5rjlebzpbbk7v62rcq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_icons_VRFbPD90k1 from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_typescript@5.7.3_vite@6.1.1_@types+node@22.13.4_jit_vxfh77tioebijsitb6itelreci/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.js";
import unhead_BkXMGzrXuM from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_@upstash+redis@1.34.4_@vercel+kv@3.0.0__cvb6blnd5rjlebzpbbk7v62rcq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1OAc18AXPH from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_@upstash+redis@1.34.4_@vercel+kv@3.0.0__cvb6blnd5rjlebzpbbk7v62rcq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_4C8Erk5HUQ from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_DLHZSPMw6B from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_@upstash+redis@1.34.4_@vercel+kv@3.0.0__cvb6blnd5rjlebzpbbk7v62rcq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_gQCElj6DJC from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_@upstash+redis@1.34.4_@vercel+kv@3.0.0__cvb6blnd5rjlebzpbbk7v62rcq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_xAhJjW15H9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_@upstash+redis@1.34.4_@vercel+kv@3.0.0__cvb6blnd5rjlebzpbbk7v62rcq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_iSaaeKZqhm from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_@upstash+redis@1.34.4_@vercel+kv@3.0.0__cvb6blnd5rjlebzpbbk7v62rcq/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_SHRnwJmLTO from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_@upstash+redis@1.34.4_@vercel+kv@3.0.0__cvb6blnd5rjlebzpbbk7v62rcq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_feJZtqgAfO from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_@upstash+redis@1.34.4_@vercel+kv@3.0.0__cvb6blnd5rjlebzpbbk7v62rcq/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import components_plugin_zlvi6dcIsi from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_4IN4pGVS0S from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_@upstash+redis@1.34.4_@vercel+kv@3.0.0__cvb6blnd5rjlebzpbbk7v62rcq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_bgJ9rUnfzK from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.1.1_@types+node@22.13.4_jiti@2.4.2_sass@1.85.0_stylus_nse4ufjofelgnjhlq47fvdthcy/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import auth_redirect_oZ7X7bJSWr from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import unocss_RAHspWIKy3 from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/unocss.mjs";
import vuetify_no_client_hints_SDQi6lZnI0 from "/vercel/path0/node_modules/.pnpm/vuetify-nuxt-module@0.18.3_magicast@0.3.5_typescript@5.7.3_vite@6.1.1_@types+node@22.13.4_jit_vxfh77tioebijsitb6itelreci/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-no-client-hints.js";
import modal_1Ii8VAZvC6 from "/vercel/path0/layers/ui/plugins/modal.ts";
import toast_cdbKkBDyZ4 from "/vercel/path0/layers/ui/plugins/toast.ts";
import custom_icons_039LGJjGSP from "/vercel/path0/app/plugins/custom-icons.ts";
import directives_UCglB6puIs from "/vercel/path0/app/plugins/directives.ts";
import vuetify_nuxt_plugin_client_moE2XX1Cig from "/vercel/path0/node_modules/.cache/nuxt/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  handleArrayWIthProperties_ypljaokOlv,
  revive_payload_client_ZG5wPPp8u4,
  vuetify_icons_VRFbPD90k1,
  unhead_BkXMGzrXuM,
  router_1OAc18AXPH,
  supabase_client_4C8Erk5HUQ,
  payload_client_DLHZSPMw6B,
  navigation_repaint_client_gQCElj6DJC,
  check_outdated_build_client_xAhJjW15H9,
  view_transitions_client_iSaaeKZqhm,
  chunk_reload_client_SHRnwJmLTO,
  cross_origin_prefetch_client_feJZtqgAfO,
  components_plugin_zlvi6dcIsi,
  prefetch_client_4IN4pGVS0S,
  plugin_bgJ9rUnfzK,
  auth_redirect_oZ7X7bJSWr,
  unocss_RAHspWIKy3,
  vuetify_no_client_hints_SDQi6lZnI0,
  modal_1Ii8VAZvC6,
  toast_cdbKkBDyZ4,
  custom_icons_039LGJjGSP,
  directives_UCglB6puIs,
  vuetify_nuxt_plugin_client_moE2XX1Cig
]