<script setup lang="ts">
import { provideUseId } from '@headlessui/vue'
import { Analytics } from '@vercel/analytics/nuxt'

const user = useSupabaseUser()
provideUseId(() => useId().replace(/[-:]/, '_'))
</script>

<template>
  <Analytics />
  <TProviderDialog />
  <TProviderToast />
  <NuxtLayout :name="user ? 'default' : 'empty'">
    <NuxtPage />
  </NuxtLayout>
</template>


<style lang="sass">
body, .scrollbar, *
  --sb-track-color: #373d40
  --sb-thumb-color: #ffffff
  --sb-size: 6px
  &::-webkit-scrollbar
    width: var(--sb-size)
    height: var(--sb-size)
  &::-webkit-scrollbar-track
    background: var(--sb-track-color)
    border-radius: 16px
  &::-webkit-scrollbar-thumb
    background: var(--sb-thumb-color)
    border-radius: 16px
  @supports not selector(::-webkit-scrollbar)
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color)

</style>